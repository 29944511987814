/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
// h2,
// h3,
// h4,
h5,
h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
// img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
// ul,
// li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
// ul {
//   list-style: none;
// }
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Using SCSS variables to store breakpoints */
$breakpoint-tablet: 1000px;

.Base {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.Nav {
  @media (max-width: $breakpoint-tablet) {
    // color: purple;
    // background-color: purple;
  }

  width: 100%;
  background-color: #b8e5b0;
  padding: 5px;
  border-bottom: 3px solid #296041;

  .links {
    width: 45%;
    min-width: 750px;
    margin: auto;
    // padding-left: 30%;
    display: flex;
    text-align: center;
    .navItems {
      margin: auto;
      color: black;
      text-decoration: none;
      font-weight: bold;

      // padding-right: 5%;
      // padding-left: 5%;
      // text-decoration: white;
    }
  }

  .logo {
    height: 6em;
  }
}

.About {
  // @media screen {
  // .h2 {
  //   font-size: 3rem;
  //   font-weight: bold;
  //   // color: purple;
  // }
  // }
  width: 100%;
  .aboutBlurb {
    margin: auto;
    margin-top: 50px;
    min-width: 750px;
    width: 40%;
    line-height: 1.2;
  }

  .photoArray {
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding: 1%;
    // width: 100%;

    // max-height: 300px;
    // max-width: 300px;

    .promo {
      margin-right: 1%;
      border-radius: 8%;
      border: 3px solid black;
      // max-height: 70%;
      height: 300px;
      max-width: 20%;
    }
  }
}

.footer {
  background-color: #b8e5b0;
  border-bottom: 3px solid #296041;
  border-top: 3px solid #296041;
  width: 100%;
  // position: absolute;
  bottom: 0;

  flex-flow: column;
  text-align: center;
  justify-content: center;

  .innerFooter {
    width: 100%;
    justify-content: center;
    line-height: 0.1;
  }
}

.banner {
  border-bottom: 3px solid black;
  max-width: 100%;
  // margin: 0;
  // padding: 0;
  max-height: 15%;
  border-bottom: 3px solid #296041;

  // position: absolute;
  // top: 100px;
}

// .aboutBlurb {
//   // padding: 1rem;
//   margin-bottom: 2rem;
// }

.contactBlurb {
  display: flex;
}

.Card {
  display: flex;
  width: 300px;
  height: 300px;
}

.picContainer {
  // border: 1px solid grey;

}
.modelPic {
  width: 100%;
  height: 30%;
}


.cardGrid{
  display: flex;
  flex-direction: row;

  justify-content: space-evenly;





  .cardContainer{
    width: 30%;
    height: 20%;
  }



  .subText{
    // border: 1px solid grey;
  }

}

.modeltitle{
  display: flex;
  width: 100%;
justify-content: center;
}

.About2{
  display: flex;
  justify-content: center;
}